module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145459351-2"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":620},
    },{
      plugin: require('../node_modules/gatsby-plugin-webmention/gatsby-browser.js'),
      options: {"plugins":[],"username":"pikseladam.com","identity":{"github":"tcgumus","twitter":"tc_gumus","email":"tunacaglargumus@gmail.com"},"mentions":true,"pingbacks":true,"domain":"pikseladam.com","fetchLimit":10000,"token":"wQbZM_rTu2eicm5xt2Fd5Q"},
    }]
